import { jwtDecode } from "jwt-decode"
import { logout } from "./main.global"

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const token = useCookie("sam_token")
  if(!token.value) { return abortNavigation() }

  const decodedToken = jwtDecode<TokenBody>(token.value)

  if(decodedToken.organizations.length === 0) { return logout(to.path) }
})